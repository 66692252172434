import './App.css';
import React, {Component} from "react";
import Header from "./components/Header";
import About from "./components/About";
import Resume from "./components/Resume";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import {BrowserRouter} from "react-router-dom";
import Services from "./components/Services";
import $ from "jquery";
import { motion } from "framer-motion";

class App extends Component {

    onScroll = () => {
        const scrollY = $(window).scrollTop();
        console.log(`onScroll, window.scrollY: ${scrollY}`);
        console.log(`win height: ${window.innerHeight}`);
        let resume = $('#skill');
        this.setState({
            scrollY: scrollY,
            hasScrollMidResume: resume.offset().top - scrollY <  window.innerHeight / 2
        })
    }

    constructor(props) {
        super(props);
        this.state = {scrollY: 0, hasScrollMidResume: false};
    }

    componentDidMount() {
        console.log('window ' + $(window).height());
        console.log('document ' + $(document).height());
        $(window).scroll(this.onScroll);
    }

    /// TODO Add internationalization
    /// TODO Add themes ?
    render() {
        return (
            <BrowserRouter>
                <div className="App"
                     onScroll={this.onScroll}
                >
                    <Header/>
                    <motion.div animate={{
                        /// Fully opaque at ~mid window height
                        opacity: ((-100 + this.state.scrollY*1.5) / ($(window).height()))
                    }}>
                        <About/>
                    </motion.div>
                    <Resume hasScrollMidResume={this.state.hasScrollMidResume}/>
                    <Services scrollY={this.state.scrollY}/>
                    <Contact />
                    <Footer/>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
