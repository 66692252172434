import React, {Component} from 'react';
import {motion} from "framer-motion";
import $ from "jquery";

class Resume extends Component {

    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    render() {
        console.log(this.props.hasScrollMidResume);
        return (
            <section id="resume">
                <div className="row education">
                    <div className="three columns header-col">
                        <h1><span>Diplômes</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        <div className="row item">
                            <div className="twelve columns">
                                <div key="IMERIR"><h3>IMERIR : Manager de Systèmes Informatiques et Robotiques</h3>
                                    <p className="info">Bac +5<span>&bull;</span><em
                                        className="date">Nov. 2020</em></p>
                                    <p>Institut Méditérranéen d'Etudes et de Recherche en Informatique et Robotique</p>
                                    <p>Mention : Bien</p>
                                    <p>Options Smart Application : Intelligence Artificielle</p>
                                    <p>Erasmus Semestre à Groningen, Pays-Bas, Minor IT Management</p>
                                </div>
                                <div key="SNIR"><h3>Lycée pablo Picasso : BTS Systèmes Numériques Informatiques et
                                    Réseaux</h3>
                                    <p className="info">Bac +2<span>&bull;</span><em
                                        className="date">Août. 2017</em></p>
                                    <p>Mention : Bien</p>
                                </div>
                                <div key="Bac"><h3>Lycée pablo Picasso : Bac Sciences et Technologies de l'Industrie et
                                    du Développement Durable</h3>
                                    <p className="info">Bac<span>&bull;</span><em
                                        className="date">Août. 2015</em></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row work">

                    <div className="three columns header-col">
                        <h1><span>Travail</span></h1>
                    </div>

                    <div className="nine columns main-col">
                        <div key="KeruCorp"><h3 className="cupicorp">KeruCorp</h3>
                            <p className="info">CTO<span>&bull;</span> <em className="date">Nov. 2020 - Aujourd'hui</em>
                            </p>
                            <p>Créée par 3 amis en sortant d'école la KeruCorp vise à proposer une application de
                                rencontre innovante (Kerubi) implicant de l'intelligence artificielle ainsi qu'un
                                business model
                                avantageux pour toutes les parties </p>
                        </div>
                        <div key="NW"><h3 className="nw">Numeric Wave</h3>
                            <p className="info">Apprenti Manager Systèmes Informatiques et Robotiques<span>&bull;</span>
                                <em className="date">Avr. 2018 - Oct. 2020</em></p>
                            <p>Numeric Wave est une entreprise de développement informatique basée sur Perpignan, j'ai
                                pu
                                réaliser chez eux mon apprentissage et voir évoluer la société pour passer d'une
                                start-up à une société renommée dans le
                                département. Cette expérience a été pour moi une des meilleures et la plus instructive
                                jusqu'à présent</p>
                        </div>
                    </div>
                </div>


                <div className="row skill" id="skill">

                    <div className="three columns header-col">
                        <h1><span>Talents</span></h1>
                    </div>

                    <div className="nine columns main-col">

                        <p>De part mes études et mon apprentissage je possède une bonne connaissance des technologies
                            web, telles que Symfony, React.js ou bien encore les plus classiques Php, Html, Javscript et
                            Css.
                            J'ai aussi des acquis dans le domaine de l'intelligence artificielle avec une spécialisation
                            dans la réalisation de chatbot.
                        </p>
                        <div className="bars">
                            <ul className="skills">
                                <motion.div animate={{
                                    translateX: (this.props.hasScrollMidResume ? 0 : window.outerWidth)
                                }}
                                            transition={{duration: 0.3}}
                                >
                                    <li key="Symfony">
                                        <span style={{
                                            width: "83%", backgroundColor: "#005f00"
                                        }}
                                              className="bar-expand"></span><em>Symfony</em>
                                    </li>
                                </motion.div>
                                <motion.div animate={{
                                    translateX: (this.props.hasScrollMidResume ? 0 : -window.outerWidth)
                                }}
                                            transition={{duration: 0.6}}
                                >
                                    <li key="flutter">
                                    <span style={{
                                        width: "72%", backgroundColor: "#4649f2"
                                    }}
                                          className="bar-expand"></span><em>Flutter</em>
                                    </li>
                                </motion.div>
                                <motion.div animate={{
                                    translateX: (this.props.hasScrollMidResume ? 0 : window.outerWidth)
                                }}

                                            transition={{duration: 0.9}}
                                >
                                    <li key="python">
                                    <span style={{
                                        width: "76%", backgroundColor: "#bf853d"
                                    }}
                                          className="bar-expand"></span><em>Python</em>
                                    </li>
                                </motion.div>
                                <motion.div animate={{
                                    translateX: (this.props.hasScrollMidResume ? 0 : -window.outerWidth)
                                }}

                                            transition={{duration:1.2}}
                                >
                                    <li key="react">
                                    <span style={{
                                        width: "53%", backgroundColor: "#50bbd7"
                                    }}
                                          className="bar-expand"></span><em>React.js</em>
                                    </li>
                                </motion.div>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Resume;
