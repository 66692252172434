import React, {Component} from 'react';
import '@material/react-snackbar/dist/snackbar.css';
import {Snackbar} from '@material/react-snackbar';
import $ from "jquery";


function GetNavBar(props) {
    if (props.value)
        return (<Snackbar
            message="Message envoyé !"
            actionText="Fermer"
        />);
    return (<div></div>);
}

class Contact extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            fields: {},
            errors: {},
            mailSent: false
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Email
        if (!fields["contactEmail"]) {
            formIsValid = false;
            errors["contactEmail"] = "J'ai besoin de votre email pour vous contacter";
        }

        if (typeof fields["contactEmail"] !== "undefined") {
            let lastAtPos = fields["contactEmail"].lastIndexOf('@');
            let lastDotPos = fields["contactEmail"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["contactEmail"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["contactEmail"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["contactEmail"] = "L'adresse n'est pas valide";
            }
        }

        if (!formIsValid)
            errors["submit"] = "Veuillez corriger les erreurs";

        this.setState({errors: errors});
        return formIsValid;
    }

    contactSubmit(e) {
        e.preventDefault();
        // if(this.handleValidation()){
        // setup some local variables
        let $form = $("form");

        // Let's select and cache all the fields
        let $inputs = $form.find("input, select, button, textarea");

        // Serialize the data in the form
        let serializedData = $form.serialize();

        // Let's disable the inputs for the duration of the Ajax request.
        // Note: we disable elements AFTER the form data has been serialized.
        // Disabled form elements will not be serialized.
        $inputs.prop("disabled", true);

        // Fire off the request to /form.php
        let request = $.ajax({
            url: "/mail",
            type: "post",
            data: serializedData
        });
        let rThis = this;
        // Callback handler that will be called on success
        request.done(function (response, textStatus, jqXHR) {
            rThis.setState({mailSent: true});
            $inputs.each(function (index, obj) {
                obj = $(obj);
                if (obj.prop('type') !== "submit")
                    obj.val("");
            });
        });

        // Callback handler that will be called on failure
        request.fail(function (jqXHR, textStatus, errorThrown) {
            // Log the error to the console
            console.error(
                "The following error occurred: " +
                textStatus, errorThrown
            );
        });

        // Callback handler that will be called regardless
        // if the request failed or succeeded
        request.always(function () {
            // Reenable the inputs
            $inputs.prop("disabled", false);
        });
        // } else {
        //     console.log(this.state.errors);
        // }
    }

    handleChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({fields});
    }

    render() {

        if (this.props.data) {

        }

        return (
            <section id="contact">
                <GetNavBar value={this.state.mailSent}/>
                <div className="row section-head">

                    <div className="two columns header-col">

                        <h1><span>Gardons contact</span></h1>

                    </div>

                    <div className="ten columns">

                        <h3 className="lead">Contact / Devis en ligne</h3>

                    </div>

                </div>

                <div className="row">
                    <div className="eight columns">
                        <form action="/mail" method="post" id="contactForm" name="contactForm"
                              onSubmit={this.contactSubmit.bind(this)}>
                            <fieldset>
                                <div>
                                    <label htmlFor="contactName">Nom/ Entreprise</label>
                                    <input type="text" defaultValue="" size="50" id="contactName" name="contactName"/>
                                </div>
                                <div>
                                    <label htmlFor="contactEmail">Email <span className="required">*</span></label>
                                    <input type="email" defaultValue="" size="50" id="contactEmail" name="contactEmail"
                                           required
                                           pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"
                                           onChange={this.handleChange.bind(this, "email")}
                                           value={this.state.fields["email"]}/>
                                </div>
                                <div>
                                    <label htmlFor="contactSubject">Sujet</label>
                                    <input type="text" defaultValue="" size="35" id="contactSubject"
                                           name="contactSubject"/>
                                </div>

                                <div>
                                    <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                                    <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"
                                              required></textarea>
                                </div>
                                {/* Weird fix */}
                                <div style={{textAlign: "left"}}>
                                    <label htmlFor="submit"> </label>
                                    <input className="submit" name="submit" onSubmit={this.contactSubmit.bind(this)}
                                           type="submit" value="Envoyer"/>
                                </div>
                            </fieldset>
                        </form>

                        <div id="message-warning"> Error boy</div>
                        <div id="message-success">
                            <i className="fa fa-check"></i>Your message was sent, thank you!<br/>
                        </div>
                    </div>
                    <aside className="four columns">
                        <h2>Contact</h2>
                        <p className="address">
                            <span>Clément Mitjana</span><br/>
                            <span>10 Place de la République<br/>
						         Le Soler, 66270
                   </span><br/>
                            <span>0625774453</span><br/>
                            <span>clement.mitjana@xentech.pro</span>
                        </p>
                    </aside>
                </div>
            </section>
        );
    }
}

export default Contact;
