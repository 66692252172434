import React, {Component} from 'react';
import Carousel from "../overrides/carousel";
import {motion} from "framer-motion";

class Services extends Component {

    render() {
        const divStyle = {
            height: "300px",
            width: "100%",
        }

        return (
            <section id="services">
                <h1>Services</h1>
                <div className="material-background s-bg">
                    <motion.div
                        className="row services"
                        whileHover={{
                            scale: 1.1,
                        }}
                    >
                        <div className="four columns">
                            <Carousel delay={6000} mode={"fade"} divStyle={divStyle}>
                                {[1, 2, 3].map((nbr, index) => (
                                    <div key={index}>
                                        <img className="service-pic"
                                             src={process.env.PUBLIC_URL + '/assets/images/material_' + nbr + '.jpg'}
                                             alt="Matériel"/>
                                    </div>
                                ))}
                            </Carousel>
                        </div>

                        <div className="eight columns main-col">
                            <h3>Matériel</h3>
                            <p>
                                Que vous ayez besoin d'un ordinateur ou d'une infrastructure plus complexe,
                                je saurais vous accompagner dans le choix du matériel le plus adapté à votre besoin.
                                Pour un système personnalisé je recherche les meilleures offres chez plusieurs
                                partenaires,
                                afin de vous proposer un prix des plus abordables
                            </p>
                        </div>
                    </motion.div>
                </div>
                <div className="dev-background s-bg">
                    <motion.div className="row services"
                                whileHover={{scale: 1.1}}
                    >
                        <div className="eight columns main-col">
                            <h3>Développement Web/Mobile</h3>
                            <p>
                                C'est dans ce domaine que mes compétences sont les plus étendues, aprés plusieurs années
                                d'études
                                dont certaines appuyés par des expériences professionnelles, j'estime avoir aujourd'hui
                                les
                                compétences pour réaliser des projets d'une certaine ampleur.<br/>
                                Qu'il s'agisse d'un site Web, d'une application mobile ou d'une application de bureau,
                                je vous guiderai afin de concevoir ensemble votre projet pour qu'il soit au plus près de
                                vos attentes.
                            </p>
                        </div>
                        <div className="four columns">
                            <Carousel delay={6000} mode={"fade"} divStyle={divStyle}>
                                {[3, 2, 1].map((nbr, index) => (
                                    <div key={index}>
                                        <img className="service-pic"
                                             src={process.env.PUBLIC_URL + '/assets/images/dev_' + nbr + '.jpg'}
                                             alt="Développement"/>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </motion.div>
                </div>

                <div className="formation-background s-bg">
                    <motion.div className="row services"
                                whileHover={{scale: 1.1}}
                    >
                        <div className="four columns">
                            <Carousel delay={6000} mode={"fade"} divStyle={divStyle}>
                                {[2, 1, 3].map((nbr, index) => (
                                    <div key={index}>
                                        <img className="service-pic"
                                             src={process.env.PUBLIC_URL + '/assets/images/teach_' + nbr + '.jpg'}
                                             alt="Formation"/>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        <div className="eight columns main-col">
                            <h3>Formation</h3>
                            <p>
                                Autant dans les domaines professionnels, scolaires, amicaux tout comme familiaux, j'ai
                                pu faire profiter
                                de mon savoir à mon entourage et j'apprécie le fait de transmettre des connaissances.
                                Patience, compréhension et empathie sont des vertus qui me sont utiles afin des former
                                des personnes
                                de toutes les tranches d'âges sur la pluparts des sujets liés à l'informatique.<br/>
                                Que ce soit pour une simple remise à niveaux générale ou sur certains outils,
                                que vous souhaitiez développer de nouvelles compétences ou bien que vous vouliez
                                en apprendre plus sur des sujets d'actualités tels que l'intelligence artificielle,
                                les cryptomonnaies, la programmation ou bien les objets connectés je vous aiderai afin
                                de vous en apprendre le plus possible.
                            </p>
                        </div>
                    </motion.div>
                </div>
            </section>
        );
    }
}

export default Services;
