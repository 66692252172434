import React, {Component} from 'react';
import Particles from "react-tsparticles";

class Header extends Component {
    render() {

        if (this.props.data) {

        }

        return (
            <header id="home">
                <Particles
                    id="tsparticles"
                    options={{
                        fpsLimit: 60,
                        interactivity: {
                            detectsOn: "canvas",
                            events: {
                                onClick: {
                                    enable: true,
                                    mode: "push",
                                },
                                onHover: {
                                    enable: true,
                                    mode: "connect",
                                },
                                resize: true,
                            },
                            backgroundMode: {
                                enable: true,
                                zIndex: 1
                            },
                            modes: {
                                bubble: {
                                    distance: 400,
                                    duration: 2,
                                    opacity: 0.8,
                                    size: 40,
                                },
                                push: {
                                    quantity: 4,
                                },
                                repulse: {
                                    distance: 100,
                                    duration: 0.5,
                                },
                                connect: {
                                    distance: 75,
                                    radius: 200,
                                    links: {
                                        opacity: 0.75
                                    }
                                }
                            },
                        },
                        particles: {
                            color: {
                                value: ["#5271ff","#6853e9","#4f96ba"],
                            },
                            links: {
                                color: {
                                    value: "#e8ebe4",
                                    random: false
                                },
                                distance: 120,
                                enable: true,
                                opacity: 0.4,
                                width: 1,
                                consent: false
                            },
                            collisions: {
                                enable: true,
                            },
                            move: {
                                direction: "none",
                                enable: true,
                                outMode: "bounce",
                                random: false,
                                speed: 3,
                                straight: false,
                            },
                            number: {
                                density: {
                                    enable: true,
                                    value_area: 800,
                                },
                                value: 120,
                            },
                            opacity: {
                                value: 0.4,
                            },
                            shape: {
                                type: "edge",
                            },
                            size: {
                                random: true,
                                value: 5,
                            },
                        },
                        detectRetina: true,
                    }}
                />
                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                    <ul id="nav" className="nav">
                        <li className="current"><a className="smoothscroll" href="#home">Accueil</a></li>
                        <li><a className="smoothscroll" href="#about">Présentation</a></li>
                        <li><a className="smoothscroll" href="#resume">CV</a></li>
                        <li><a className="smoothscroll" href="#services">Services</a></li>
                        <li><a className="smoothscroll" href="#contact">Contact</a></li>
                    </ul>
                </nav>
                {/*<div className="row banner">*/}
                {/*    <div className="banner-text">*/}
                {/*        <h1 className="responsive-headline">Xentech</h1>*/}
                {/*        <h3>Solutions Informatiques</h3>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <p className="scrolldown">
                    <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
                </p>

            </header>
        );
    }
}

export default Header;
