import React, {Component} from 'react';
import {Parallax} from "react-parallax";


class About extends Component {

    render() {
        return (
            <section id="about">
                <Parallax blur={0} bgImage={process.env.PUBLIC_URL + '/assets/images/bg_abs.png'} bgImageAlt="bg_about"
                          strength={200} className="parallax-about" bgImageStyle={{'margin-top': '-50px'}}>
                        <div className="row">
                            <div className="three columns">
                                <img className="profile-pic" src={process.env.PUBLIC_URL + '/assets/images/me.jpg'}
                                     alt="My Face"/>
                            </div>
                            <div className="nine columns main-col">
                                <h2>Présentation</h2>
                                <p>Né dans la ville catalane de Perpignan, j'ai depuis toujours été passionné
                                    d'informatique.
                                    C'est ce qui m'a motivé dans mes études afin de devenir Manager de Systèmes
                                    Informatiques et Réseaux.
                                    J'ai pu profiter d'une formation en apprentissage pendant plus 2 ans dans une
                                    entreprise
                                    de la région (c.f CV).
                                    Cet apprentissage m'a permis d'en apprendre beaucoup sur les méthodes appliqués dans
                                    ce
                                    mileu.
                                    De la simple consultation au développement d'une application à la pointe de la
                                    technologie,
                                    je saurais vous accompagner dans tous ces choix qui aujourd'hui comptent plus que
                                    jamais.</p>
                                <div className="row">
                                    <h2>Contact</h2>
                                    <p className="address">
                                        <span>Clément Mitjana</span><br/>
                                        <span>10 Place de la République<br/>
						         Le Soler, 66270
                                    </span><br/>
                                        <span>0625774453</span><br/>
                                        <span>mitjana.clement@hotmail.fr</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                </Parallax>
            </section>

        )
            ;
    }
}

export default About;
